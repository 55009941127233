import request from "../utils/request";

export function login(data) {
  return request({
    url: "/teacher/admin/login",
    method: "post",
    data,
  });
}
export function retrievePassword(data) {
  return request({
    url: "/teacher/admin/retrievePassword",
    method: "post",
    data,
  });
}

export function verification(data) {
  return request({
    url: "/teacher/sendCode",
    method: "post",
    data,
  });
}

export function outLogin() {
  return request({
    url: "/teacher/admin/logout",
    method: "post",
  });
}

export function getInfo() {
  return request({
    url: "/teacher/admin/getInfo",
    method: "get",
  });
}

//申请入住
export function addApply(data) {
  return request({
    url: "/teacher/addApply",
    method: "post",
    data,
  });
}
