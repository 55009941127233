<template>
  <router-view />
</template>

<style lang="less">
body {
  margin: 0;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// .el-input {
//   :deep(.el-input__wrapper) {
//     width: 280px;
//     box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color))
//       inset;
//   }
// }
.pointer{
  cursor: pointer;
}
#dialogImage{
  margin: 0px auto  !important;
  margin-top: 2% !important;

}
</style>
