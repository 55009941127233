import axios from "axios"; //引入//axios
import Cookies from "js-cookie";
import $router from "@/router/index.js";
import store from "@/store";
import { ElNotification } from "element-plus";
// let baseURL;
let baseURL = "https://app-api.badouknow.com";
// let baseURL = "https://app-api.test.badouknow.com";
// let baseURL = process.env.VUE_APP_BASE_URL;

//创建一个axios 的实例image.png
const instance = axios.create({
  baseURL, //baseURL,//url 请求路径 /代理路径
  timeout: 60000, //超时设置
  // withCredentials: true,
});

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么 给请求添加请求头
    // config.headers["Token"] = Cookies.get("token"); //给请求头添加token
    // config.headers["values"] = Cookies.get("Bearer");
    config.headers.Authorization = Cookies.get("token");
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    const { data, headers } = response;
    if (headers.authorization != undefined) {
      Cookies.set("token", headers.authorization);
    }
    if (response.data.code == 401 && !response.config.url?.includes("/getInfo")) {
      sessionStorage.clear();
      localStorage.clear();
      store.commit("saveUserInfo", null);
      // ElNotification({
      //   title: "失败",
      //   message: "登录过期，请重新登录",
      //   type: "error",
      // });
      $router.replace({ path: "/login" });
    }
    return data;
  },
  (error) => {
    return error;
  }
);
export default instance;
