import { createStore } from "vuex";
import { getInfo } from "@/api/login";

export default createStore({
  state: {
    // 用户信息
    user: null,
    crumbsTitle: null,
    appPlatform: { all: 1, is_tik_tok: 0, is_ks: 0 },
  },
  getters: {},
  mutations: {
    /**
     * 保存用户信息的同步方法
     */
    saveUserInfo(state, userInfo) {
      state.user = userInfo;
    },
    saveMenuTitle(state, title) {
      state.crumbsTitle = title;
      // console.log(state.crumbsTitle);
    },
    savePlatform(state, platform) {
      state.appPlatform = platform;
      // console.log(state.appPlatform);
    },
  },
  actions: {
    // 获取用户信息
    async fetchUserInfo() {
      return await getInfo();
    },
  },
  modules: {},
});
