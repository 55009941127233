import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";


const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import('../views/Login.vue'),
  },
  {
    path: "/applycheckina",
    name: "applycheckina",
    component: () => import('@/views/applycheckinmanagement/applyCheckinA.vue'),
  },
  {
    path: "/applycheckinb",
    name: "applycheckinb",
    component: () => import('@/views/applycheckinmanagement/applyCheckinB.vue'),
  },
  {
    path: "/",
    name: "home",
    redirect: "/datasurvey",
    component: () => import('../views/Index.vue'),
    children: [
      {
        path: "coursePage",
        name: "coursePage",
        component: () => import("@/views/coursemanagement/coures.vue"),
        meta: {
          breadList: [
            {
              name: "课程管理",
            },
          ],
        },
      },
      {
        path: "addcouresPage",
        name: "addcouresPage",
        component: () => import('@/views/coursemanagement/addCoures.vue'),
        meta: {
          breadList: [
            {
              name: "课程管理",
              to: {
                name: "coursePage",
              },
            },
            {
              name: "添加课程",
            },
          ],
        },
      },
      {
        path: "couresdetailed",
        name: "couresdetailed",
        component: () => import('@/views/coursemanagement/couresDetailed.vue'),
        meta: {
          breadList: [
            {
              name: "课程管理",
              to: {
                name: "coursePage",
              },
            },
            {
              name: "课程详情",
            },
          ],
        },
      },
      {
        path: "addpassage",
        name: "addpassage",
        component: () => import('@/views/coursemanagement/addPassage.vue'),
        meta: {
          breadList: [
            {
              name: "课程管理",
              to: {
                name: "coursePage",
              },
            },
            {
              to: {
                name: "couresdetailed",
              },
              name: "课程详情",
            },
            {
              name: "新增章节",
            },
          ],
        },
      },
      {
        //讲师管理
        path: "lecturer",
        name: "lecturer",
        component: () => import("@/views/lecturerManagement/lecturer.vue"),
        meta: {
          breadList: [
            {
              name: "讲师管理",
            },
          ],
        },
      },
      {
        //添加讲师
        path: "addlecturer",
        name: "addlecturer",
        component: () => import("@/views/lecturerManagement/addLecturer.vue"),
        meta: {
          breadList: [
            {
              to: {
                name: "addlecturer",
              },
              name: "讲师管理",
            },
            {
              name: "添加讲师",
            },
          ],
        },
      },
      {
        //订单列表
        path: "orderlist",
        name: "orderlist",
        component: () => import("@/views/ordermanagement/order.vue"),
        meta: {
          breadList: [
            {
              name: "订单管理",
            },
            {
              to: {
                name: "orderlist",
              },
              name: "订单列表",
            },
          ],
        },
      },
      {
        //订单详情
        path: "shipments",
        name: "shipments",
        component: () => import("@/views/ordermanagement/shipments.vue"),
        meta: {
          breadList: [
            {
              name: "订单管理",
            },
            {
              to: {
                name: "shipments",
              },
              name: "发货订单",
            },
          ],
        },
      },
      {
        //订单详情
        path: "orderdetails",
        name: "orderdetails",
        component: () => import("@/views/ordermanagement/orderDetails.vue"),
        meta: {
          breadList: [
            {
              name: "订单管理",
            },
            {
              to: {
                name: "orderlist",
              },
              name: "订单列表",
            },
            {
              to: {
                name: "orderdetails",
              },
              name: "订单详情",
            },
          ],
        },
      },
      {
        //营销管理
        path: "Learningcluster",
        name: "Learningcluster",
        component: () => import('@/views/marketingmanagement/LearningCluster.vue'),
        meta: {
          breadList: [
            {
              name: "营销管理",
            },
            {
              to: {
                name: "Learningcluster",
              },
              name: "学习群",
            },
          ],
        },
      },
      {
        //营销管理
        path: "seckill",
        name: "seckill",
        component: () => import("@/views/marketingmanagement/seckill.vue"),
        meta: {
          breadList: [
            {
              name: "营销管理",
            },
            {
              to: {
                name: "seckill",
              },
              name: "秒杀活动",
            },
          ],
        },
      },
      {
        //分销订单
        path: "distributionorder",
        name: "distributionorder",
        component: () => import("@/views/ordermanagement/distributionOrder.vue"),
        meta: {
          breadList: [
            {
              name: "订单管理",
            },
            {
              to: {
                name: "distributionorder",
              },
              name: "分销订单",
            },
          ],
        },
      },

      {
        //钱包
        path: "wallet",
        name: "wallet",
        component: () => import('@/views/wallet/wallet.vue'),
        meta: {
          breadList: [
            {
              name: "钱包",
            },
            {
              name: "抖音提现",
            },
          ],
        },
      },
      {
        //钱包
        path: "kswallet",
        name: "kswallet",
        component: () => import("@/views/wallet/ksWallet.vue"),
        meta: {
          breadList: [
            {
              name: "钱包",
            },
            {
              name: "快手提现",
            },
          ],
        },
      },
      {
        //钱包提现设置
        path: "setwithdraw",
        name: "setwithdraw",
        component: () => import("@/views/wallet/setWithdraw.vue"),
        meta: {
          breadList: [
            {
              name: "钱包",
            },
            {
              name: "提现设置",
            },
          ],
        },
      },
      {
        //分销市场
        path: "distribution",
        name: "distribution",
        component: () => import("@/views/distribution/distribution.vue"),
        meta: {
          breadList: [
            {
              name: "分销市场",
            },
          ],
        },
      },
      {
        //数据概况
        path: "datasurvey",
        name: "datasurvey",
        component: () => import("@/views/dataSurvey/dataSurvey.vue"),
        meta: {
          breadList: [
            {
              name: "数据概况",
            },
          ],
        },
      },
      {
        //消息中心
        path: "infocentre",
        name: "infocentre",
        component: () => import("@/views/dataSurvey/infoCentre.vue"),
        meta: {
          breadList: [
            {
              name: "消息中心",
            },
          ],
        },
      },
      {
        //账号授权
        path: "adminempower",
        name: "adminempower",
        component: () => import("@/views/adminempower/adminEmpower.vue"),
        meta: {
          breadList: [
            {
              name: "推广号",
            },
          ],
        },
      },
      {
        //账号设置
        path: "admin",
        name: "admin",
        component: () => import("@/views/systemmanagement/admin.vue"),
        meta: {
          breadList: [
            {
              name: "系统管理",
            },
            {
              name: "账号设置",
            },
          ],
        },
      },

      {
        //账号设置
        path: "sonadmin",
        name: "sonadmin",
        component: () => import("@/views/systemmanagement/sonAdmin.vue"),
        meta: {
          breadList: [
            {
              name: "系统管理",
            },
            {
              name: "子账号设置",
            },
          ],
        },
      },
      {
        //角色管理
        path: "roleadmin",
        name: "roleadmin",
        component: () => import("@/views/systemmanagement/roleAdmin.vue"),
        meta: {
          breadList: [
            {
              name: "系统管理",
            },
            {
              name: "角色设置",
            },
          ],
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
