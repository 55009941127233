import { createApp, nextTick } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import locale from "element-plus/lib/locale/lang/zh-cn";
router.beforeEach(async (to, from, next) => {
  /**
   * TODO:
   * 调用接口获取用户信息，如果获取到了就直接正常跳转
   * 如果接口获取用户信息失败，则跳转到登录页面
   */
  if (["applycheckina", "applycheckinb"].includes(to.name)) {
    next();
  }
  try {
    // console.log(store.state.user, "store.state.user");
    // 获取用户信息
    if (!store.state.user) {
      // console.log("mian用户信息", store.state.user);
      // console.log(to.name);
      if (
        to.name === "login" ||
        to.name === "applycheckina" ||
        to.name === "applycheckinb"
      ) {
        // console.log("登录页");
      } else {
        // console.log("登录页1", localStorage.getItem("userinfo"));
        if (localStorage.getItem("userinfo")) {
          let userData = localStorage.getItem("userinfo");
          store.commit("saveUserInfo", JSON.parse(userData));
        } else {
          const { code, data } = await store.dispatch("fetchUserInfo");
          if (code === 200) {
            // 保存信息
            store.commit("saveUserInfo", data);
            localStorage.setItem("userinfo", JSON.stringify(data));
          } else if (code == 401) {
            sessionStorage.clear();
            localStorage.clear();
            store.state.user = null;
            next({ name: "login" });
          }
        }
        //面包屑全局筛选状态
        if (localStorage.getItem("appPlatform")) {
          let appPlatform = localStorage.getItem("appPlatform");
          // console.log(JSON.parse(appPlatform));
          store.commit("savePlatform", JSON.parse(appPlatform));
        }
      }
    }
    // }
  } catch (error) {
    next({ name: "login" });
  }

  if (!store.state.user && to.name !== "login") {
    next({ name: "login" });
  } else if (to.name === "login" && store.state.user) {
    // 如果是跳转到登录页面 并且 store中有用户信息的话就跳转到首页
    next("/");
  }

  next();
});
createApp(App)
  .use(store)
  .use(router)
  // .use(ElementPlus)
  .use(ElementPlus, { locale })
  .mount("#app");
